<template>
  <header>
    <div class="container py-3">
      <div class="flex items-center">
          <img src="@/assets/images/common/tinkoff-logo-text.svg" alt="LOGO" width="150" height="34">
          <div class="flex-grow"><slot/></div>
          <a :href="'tel:'+phone" class="no-underline text-right">{{ phone }}</a>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "tks-header",
  props: ['phone'],
}
</script>

<style scoped>
header {
  background-color: #ffffff;
  line-height: 1;
}

.phone {
  text-decoration: none;
}

.header-container {
  padding-top: 13px;
  padding-bottom: 13px;
}
</style>