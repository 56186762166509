<template>
  <site-header :phone="phone"></site-header>
  <gradient-delimiter height="32px" start-color="#e6e6e7" end-color="#f6f7f8"/>
  <div class="bg-[#f6f7f8] pb-24">
    <div class="container">
      <div class="text-center pt-12 text-[rgba(0,0,0,.54)] text-sm">Тинькофф Платинум</div>
      <h1 class="mt-8 w-2/3 mx-auto text-center">Кредитка с кэшбэком рублями</h1>
      <div class="text-center mt-8">Мгновенное решение, нужен только паспорт. Бесплатно доставим кредитную карту сегодня</div>
      <yellow-button class="block mx-auto my-12" @click="gotoForm">Оформить карту</yellow-button>

      <img src="@/assets/images/top.webp" alt="top">

      <div class="md:grid grid-cols-6 gap-12 mt-16">
        <div class="col-span-2 bg-[#9299a2] rounded-3xl text-white p-8 md:mb-0 mb-4">
          <img src="@/assets/images/perc.svg" alt="perc">
          <h3 class="mt-8">От 12% годовых</h3>
          <div class="mt-2">Процентная ставка</div>
        </div>

        <div class="col-span-2 bg-[#eaecee] rounded-3xl p-8 md:mb-0 mb-4">
          <img src="@/assets/images/bag.svg" alt="perc">
          <h3 class="mt-8">До 55 дней</h3>
          <div class="mt-2">Беспроцентный период за любые покупки по карте</div>
        </div>

        <div class="col-span-2 bg-[#eaecee] rounded-3xl p-8 md:mb-0 mb-4">
          <img src="@/assets/images/limit.svg" alt="perc">
          <h3 class="mt-8">До 700 000 ₽</h3>
          <div class="mt-2">Кредитный лимит</div>
        </div>

        <div class="col-span-3 bg-[#eaecee] rounded-3xl p-8 md:mb-0 mb-4">
          <img src="@/assets/images/update.svg" alt="perc">
          <h3 class="mt-8">120 дней</h3>
          <div class="mt-2">Беспроцентный период за погашение картой других кредитов</div>
        </div>

        <div class="col-span-3 bg-[#9299a2] rounded-3xl text-white p-8 md:mb-0 mb-4">
          <img src="@/assets/images/gift.svg" alt="perc">
          <h3 class="mt-8">До 12 месяцев</h3>
          <div class="mt-2">Без процентов. За технику, одежду и другие покупки в рассрочку</div>
        </div>
      </div>

      <h2 class="header">Наша лучшая кредитная карта</h2>
      <div class="md:grid grid-cols-3 gap-12 mt-16">
        <div class="col-span-2 rounded-3xl">
          <img src="@/assets/images/platinum.webp" alt="perc">
        </div>

        <div>
          <h3 class="mt-8">Мгновенное решение по кредиту</h3>
          <div class="mt-4">Одобрение кредита без поручителей и справок о доходах</div>

          <h3 class="mt-8">Бесплатная доставка карты уже сегодня</h3>
          <div class="mt-4">При оформлении до 20:00. Более поздние заказы доставим завтра. Время и место можно выбрать после подачи заявки</div>
        </div>
      </div>

      <div class="md:grid grid-cols-2 gap-12 mt-16">
        <div class="bg-[#eaecee] rounded-3xl p-8 md:mb-0 mb-4">
          <img src="@/assets/images/update.svg" alt="perc">
          <h3 class="mt-8">Удобное пополнение и снятие</h3>
          <div class="mt-2">В любых банкоматах. Бесплатно пополняйте кредитку наличными или переводом с других карт</div>
        </div>

        <div class="bg-[#9299a2] rounded-3xl text-white p-8">
          <div class="flex">
            <img class="w-[56px] h-[56px]" src="@/assets/images/b1.svg" alt="perc">
            <img class="w-[56px] h-[56px] -ml-2" src="@/assets/images/b2.svg" alt="perc">
            <img class="w-[56px] h-[56px] -ml-2" src="@/assets/images/b3.png" alt="perc">
          </div>
          <h3 class="mt-8">Кэшбэк до 30% бонусами</h3>
          <div class="mt-2">Копите бонусы за покупки и тратьте по курсу 1 бонус = 1 рубль</div>
        </div>
      </div>

      <h2 class="header">Оформите кредитную карту за 5 минут</h2>
      <div class="md:grid grid-cols-3 gap-12 my-16">
        <div class="rounded-3xl text-center bg-white p-8 mob-mar mt-12 md:mt-0">
          <div class="bg-[#9299a2] text-white numbers flex w-[56px] h-[56px] items-center justify-center rounded-2xl text-2xl mx-auto -mt-14 mb-8">1</div>
          <div class="mt-4 text-center">Заполните онлайн-заявку — вам не нужно посещать банк</div>
        </div>

        <div class="rounded-3xl text-center bg-white p-8 mob-mar mt-12 md:mt-0">
          <div class="bg-[#9299a2] text-white numbers flex w-[56px] h-[56px] items-center justify-center rounded-2xl text-2xl mx-auto -mt-14 mb-8">2</div>
          <div class="mt-4 text-center">Узнайте решение банка сразу после заполнения заявки</div>
        </div>

        <div class="rounded-3xl text-center bg-white p-8 mob-mar mt-12 md:mt-0">
          <div class="bg-[#9299a2] text-white numbers flex w-[56px] h-[56px] items-center justify-center rounded-2xl text-2xl mx-auto -mt-14 mb-8">3</div>
          <div class="mt-4 text-center">Банк бесплатно доставит карту, куда удобно</div>
        </div>
      </div>
    </div>

    <app-form
        url="https://www.tinkoff.ru/cards/credit-cards/tinkoff-platinum/iframe/form/"
    />
  </div>
  <site-footer :phone="phone" disclaimer="При положительном решении Банка и оформлении доставки до 20:00, Карта будет доставлена в день оформления заявки либо на следующий день, за исключением регионов, где доставка карт не осуществляется представителями Банка." copyright="АО «Тинькофф Банк», официальный сайт, лицензия ЦБ РФ № 2673" :product-links="pLinks" :bank-links="[]"/>
</template>

<script>

import '@/assets/tailwind.css';
import SiteHeader from "@/components/site-header";
import GradientDelimiter from "@/components/gradient-delimiter";
import SiteFooter from "@/components/site-footer-v2";
import YellowButton from "@/components/yellow-button";
import AppForm from "@/components/app-form";

export default {
  name: 'App',
  components: {AppForm, YellowButton, GradientDelimiter, SiteHeader, SiteFooter},

  data: () => ({
    phone: process.env.VUE_APP_PHONE,
    pLinks: [
      {text:'Тарифы по карте', link: '/docs/credit_cards-tariffs-platinum.pdf'},
      {text:'Программа лояльности Браво', link: '/docs/loyalty-programs-bravo-rules.pdf'},
      {text:'Условия договора', link: '/docs/credit-cards-consumer-loan.pdf'},
      {text:'Программа лояльности Тинькофф Таргет', link: '/docs/target.pdf'},
      {text:'Тариф по услуге «Перевод Баланса»', link: '/docs/balance-transfer-service-tariff-plan-tpb-1_8.pdf'},
      {text:'Рассрочка', link: '/docs/lease.pdf'},
    ],
  }),

  methods: {
    gotoForm() {
      window.scrollTo({
        top: document.getElementById('form-iframe').offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }
}

</script>

<style lang="scss">

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans.woff2');
  font-weight: 100;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Medium.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans_Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'haas';
  src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("assets/fonts/fa-regular-400.eot");
  src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("assets/fonts/fa-solid-900.eot");
  src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 15px;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  color: #333333;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

.container {
  @media screen and (max-width: 575px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

h1, h2, h3 {
  font-family: 'TinkoffSans', sans-serif;
  font-weight: 400;
}

h1, h2 {
  font-weight: 700;
  font-variant-ligatures: no-contextual;
  font-size: 44px;
  line-height: 48px;
  letter-spacing: 0;
  @media screen and (max-width: 575px) {
    font-size: 32px;
    line-height: 35px;
  }
}

h2 {
  @media screen and (max-width: 575px) {
    font-size: 28px;
    line-height: 32px;
  }
}

h3 {
  font-size: 24px;
  line-height: 30px;
}

.header {
  @apply mt-24 w-2/3 mx-auto text-center;
}

::selection {
  background: rgba(255, 221, 45, .32) !important;
  color: rgba(0, 0, 0, .8) !important;
}

.numbers {
  font-family: TinkoffSans, pfhighway, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
  font-weight: 100;
}

</style>
