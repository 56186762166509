<template>
  <button @click="$emit('action')">
    <slot/>
  </button>
</template>

<script>
export default {
  name: "yellow-button",
}
</script>

<style scoped>
button {
  padding: 20px 30px;
  background-color: #ffdd2d;
  transition-duration: .5s;
  transition-property: background-color, border-color, box-shadow;
  transition-timing-function: ease;
  border-radius: 5px;
}

button:hover {
  background-color: #fab619;
}

</style>